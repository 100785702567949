@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

$color-white: #ffffff;
$color-primary: #2c42af;
$color-error: #d42a2a;
$color-success: #26ca6d;

.verify-transaction {
  min-height: 100vh;
  display: flex;
  flex-flow: column;

  .transaction-header {
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    padding: 0 2rem;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;

    .logo {
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;

      img {
        width: 160px;
        height: auto;
      }
    }
  }

  .transaction-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto;
    .logo {
      img {
        width: 200px;
        height: auto;
      }
    }
  }

  .transaction-container {
    padding: 2rem;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    box-sizing: border-box;

    .search-transaction {
      border-radius: 1rem;
      margin: 2rem 0;
      display: flex;
      flex-flow: column;

      .form-row {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 1rem;

        @media (max-width: 1023px) {
          grid-template-columns: 1fr;
        }
      }

      .form-item {
        margin-bottom: 1rem;
        label {
          display: block;
          font-weight: 700;
          margin-bottom: 0.5rem;
        }

        input {
          width: 100%;
          border: none;
          background-color: #f4f4f4;
          font-family: "Open Sans", sans-serif;
          font-size: 1rem;
          padding: 1rem;
          outline: none;
          box-sizing: border-box;
          border-radius: 0.5rem;
        }
      }

      button {
        padding: 1rem;
        font-family: "Open Sans", sans-serif;
        border: none;
        background-color: $color-primary;
        color: $color-white;
        outline: none;
        cursor: pointer;
        font-weight: 700;
        font-size: 1rem;
        border-radius: 0.5rem;

        &:disabled {
          display: none;
        }
      }
    }
  }

  .verify-container {
    .string-value {
      word-break: break-all;
    }

    .verification-alert {
      margin-top: 1rem;
      width: max-content;
      margin-left: auto;
      color: $color-white;
      padding: 1rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 0.5rem;
      }

      &.success {
        background-color: $color-success;
      }
      &.error {
        background-color: $color-error;
      }
    }
  }
}
